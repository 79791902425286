import Axios from 'axios';
import { baseUrl } from '../config';
import { auth } from './firebase';
import { Magic } from 'magic-sdk';

export const client = Axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

const magic = new Magic('pk_live_DA773B8C8AD66D52');

let _idToken: string | null = null;

export const getIdTokenStatic = () => _idToken;

export const getIdToken = async () => {
    let _idToken = '';
    try {
        _idToken = await magic.user.getIdToken();
        // console.log('MAGIC', _idToken);
    } catch (e) {
        console.log('MAGIC ERROR', e);
    }

    return _idToken;
};

export const getErrorMessage = (error: unknown) => {
    if (Axios.isAxiosError(error)) {
        return error.response?.data?.errorMessage || error.response?.data?.message || 'Something went wrong';
    }
    return (error as Error)?.message || 'Something went wrong';
};

export const getCsvUri = (type: string) => {
    const params = new URLSearchParams(window.location.search);
    const newUrl = new URL(baseUrl + '/csv/' + type);
    newUrl.search = params.toString();
    return newUrl.toString();
};

client.interceptors.request.use(
    async (config) => {
        const token = await getIdToken();
        (config as any).headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    (error) => Promise.reject(error)
);

client.interceptors.response.use(
    (success) => success,
    async (error) => {
        if (error.response?.status === 401) {
            await magic.user.logout();
        } else if (String(error.response?.status).startsWith('4') && error.response?.status !== 404) {
            console.warn(error.response.data.errorMessage);
        }

        return Promise.reject(error);
    }
);
