import { FC, useCallback, useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Button } from 'primereact/button';
import { AuthData, getAuthData, Role, signInWithGoogle } from "../utils/auth";
import { auth } from "../utils/firebase";
import {Magic} from "magic-sdk";


export const AdminTemplate = () => {

  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState<AuthData>();

  const checkAuth = useCallback(() => {
    setLoading(true);
    getAuthData()
      .then(setAuthorized)
      .catch(() => setAuthorized(undefined))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (loading) {
    console.log('1');
    return <LoadingTemplate />;
  }

  if (!authorized) {
    console.log('2');
    return <SignInTemplate onComplete={checkAuth} />;
  }

  console.log('3', authorized);
  if (authorized?.user?.role !== Role.ADMIN) {
    console.log('4', authorized);
    return <ForbiddenTemplate />;
  }

  return <AuthorizedTemplate />;
};

const AuthorizedTemplate = () => (
    <>
      <header className="bg-blue-900 text-white py-4">
        <div className="container px-4 flex items-center justify-end gap-12">
          <nav className="flex items-center justify-between gap-12 w-full">
            <ul className="flex flex-row justify-start items-center gap-4">
              <li><NavLink to="/" end className={({isActive}) => isActive ? 'text-orange-500' : ''}>Client Zone</NavLink></li>
            </ul>
            <div className="flex flex-row justify-end items-center gap-12">
              <ul className="flex flex-row justify-end items-center gap-4">
                <li><NavLink to="/admin" end className={({isActive}) => isActive ? 'text-orange-500' : ''}>Stats</NavLink></li>
                {/*<li><NavLink to="/admin/tweeters" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Tweeters</NavLink></li>*/}
                <li><NavLink to="/admin/keywords" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Keywords</NavLink></li>
                <li><NavLink to="/admin/tweets" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Tweets</NavLink></li>
                <li><NavLink to="/admin/feed" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Feed</NavLink></li>
              </ul>
              <ul className="flex flex-row justify-end items-center gap-4">
                <li><NavLink to="/admin/keys" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Keys</NavLink></li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="container py-12 px-4">
        <Outlet />
      </div>
    </>
);
const SignInTemplate: FC<{ onComplete: () => void }> = ({ onComplete }) => {

  const [loading, setLoading] = useState(false);

  const handleSignIn = useCallback(() => {
    setLoading(true);
    signInWithGoogle().then(onComplete).catch(() => setLoading(false));
  }, [onComplete]);

  return (
      <div className="container py-12 px-4">
        <h1>Authenticate</h1>
        <p className="mb-12">Sign in to access the admin panel.</p>

        <Button icon="pi pi-lock" label="Sign in with Google" onClick={handleSignIn} loading={loading} />

      </div>
  )
};
const LoadingTemplate = () => (<div className="container py-12 px-4">
<h1>Authenticate</h1>
<p className="mb-12">Loading...</p>
</div>);
const ForbiddenTemplate = () => (<div className="container py-12 px-4">
<h1>Forbidden</h1>
<p className="mb-12">Access forbidden</p>
<Button icon="pi pi-lock" label="Sign out" onClick={() => { auth.signOut(); window.location.reload(); }} />
</div>);
