import React, {FC, Suspense, useState, useCallback, useEffect, useRef} from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { Loading } from "../components/Loading";
import { Tweet } from "../types/models";
import { client } from "../utils/client";
import { useScoredQueryParams } from "../hooks/useScoredQueryParams";
import { throttle } from "lodash";
import { Await, defer, NavLink, useLoaderData } from "react-router-dom";
import {StatsTable} from "../components/StatsTable/StatsTable";
import {Magic} from "magic-sdk";
import { Tweet as ReactTweet } from "react-tweet";

type Response = {
    response: TweetsPageProps;
};

type TweetsPageProps = {
    tweets: Tweet[];
    next?: string;
};

const magic = new Magic('pk_live_DA773B8C8AD66D52');

const MainPage: FC = () => {
    const [period, setPeriod] = useState("hour");
    const [keyword, setKeyword] = useState("");
    const [isUnique, setIsUnique] = useState(false);
    const [full, setFull] = useState(false);
    const feedRef = useRef<HTMLDivElement | null>(null);
    const [selectedTab, setSelectedTab] = useState<'tracked' | 'new'>('new');

    console.log('isUnique ', isUnique);
    console.log('keyword ', keyword);

    const handlePeriodChange = useCallback((period: string) => () => {
        setPeriod(period);
    }, []);

    const handleKeywordReset = useCallback((keyword: string) => () => {
        setKeyword(keyword);
    }, []);

    const { response } = useLoaderData() as Response;
    useScoredQueryParams();

    const [tweetsData, setTweetsData] = useState<TweetsPageProps>(response);

    const loadMoreTweets = async () => {
        console.log("loading more tweets", tweetsData);
        if (tweetsData.next) {
            let endpoint = `/tweets?cursor=${tweetsData.next}&period=${period}&keyword=${keyword}&limit=8&is_unique=${isUnique}`;
            if (selectedTab === 'new') {
                endpoint = `/feed?cursor=${tweetsData.next}&period=${period}&q=${keyword}&limit=8&is_unique=${isUnique}`;
            }
            let newTweets = await client.get(endpoint);
            await setTweetsData({
                ...tweetsData,
                tweets: [...tweetsData.tweets, ...newTweets.data.tweets],
                next: ''
            });
        }
    };

    const resetTweets = async () => {
        await setTweetsData({
            ...tweetsData,
            tweets: [],
            next: ''
        });

        let endpoint = `/tweets?period=${period}&limit=8&is_unique=${isUnique}`;
        if (selectedTab === 'new') {
            endpoint = `/feed?period=${period}&limit=8&is_unique=${isUnique}`;
        }
        const newTweets = await client.get(endpoint);

        await setTweetsData((prevTweetsData) => ({
            ...prevTweetsData,
            tweets: [...newTweets.data.tweets],
            next: newTweets.data.next,
        }));
    };

    const resetTweetsWithKeyword = async () => {
        let endpoint = `/tweets?period=${period}&keyword=${keyword}&limit=8&is_unique=${isUnique}`;
        if (selectedTab === 'new') {
            endpoint = `/feed?period=${period}&q=${keyword}&limit=8&is_unique=${isUnique}`;
        }
        const newTweets = await client.get(endpoint);
        // console.log(...tweetsData.tweets);
        // console.log(...newTweets.data.tweets);
        setTweetsData({
            ...tweetsData,
            tweets: [],
            next: newTweets.data.next,
        });
        setTimeout(() => {
            setTweetsData({
                ...tweetsData,
                tweets: [...newTweets.data.tweets],
                next: newTweets.data.next,
            })
        }, 1000);
    };

    useEffect(() => {
        if (window.location.pathname.includes('admin')) {
            setFull(true);
        }
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';

        return () => { document.body.style.overflow = 'unset'; };
    }, []);

    useEffect(() => {
        const handleScroll = async (event: { target: any; }) => {
            console.log("scrolling");
            const target = event.target;
            const atBottom = target.scrollHeight - target.scrollTop === target.clientHeight;
            if (atBottom) {
                await loadMoreTweets();
            }
        };

        const throttledHandleScroll = throttle(handleScroll, 500);

        if (feedRef.current) {
            feedRef.current.addEventListener("scroll", throttledHandleScroll);
        }

        return () => {
            if (feedRef.current) {
                feedRef.current.removeEventListener("scroll", throttledHandleScroll);
            }
        };
    }, [tweetsData, period, keyword, feedRef, isUnique]);


    useEffect(() => {
        resetTweets();
    }, [period, selectedTab, isUnique]);

    useEffect(() => {
        resetTweetsWithKeyword();
    }, [keyword, isUnique]);

    return (
        <>
            <div className="flex flex-row" id="stuff">
                <div
                    id="live-feed"
                    ref={feedRef}
                    className="flex-1 p-4 overflow-y-auto  hide-scrollbar"
                    style={{ height: "80vh" }}
                >
                    <h1 className="my-0 flex items-center">
                        Live feed
                        <img
                            className="ml-2"
                            style={{ maxHeight: "20px" }}
                            src={"blinking-dot.gif"}
                        />
                    </h1>
                    <Suspense
                        fallback={<tr><td colSpan={99}><Loading /></td></tr>}
                    >
                        <Await
                            resolve={tweetsData}
                            errorElement={<div>Error loading tweets...</div>}
                        >
                            {(res: TweetsPageProps) => (
                                <>
                                    {res.tweets.map((tweet) => (
                                        <div className="light">
                                            <ReactTweet id={tweet.id} />
                                        </div>
                                    ))}
                                </>
                            )}
                        </Await>
                    </Suspense>
                </div>
                <div
                    className="flex-1 p-4 overflow-y-auto  hide-scrollbar"
                    style={{ height: "80vh" }}
                >
                    <div className="flex flex-row gap-2 text-center justify-center items-center">
                        <button onClick={() => setSelectedTab('new')}>New Keywords</button>
                        <button onClick={() => setSelectedTab('tracked')}>Tracked Keywords</button>
                        <div className="flex items-center">
                            <input
                              type="checkbox"
                              id="unique-checkbox"
                              className="w-6 h-6"
                              onChange={() => setIsUnique(!isUnique)}
                            />
                            <label htmlFor="unique-checkbox" className="ml-2">
                                Unique
                            </label>
                        </div>
                    </div>
                    <br/>
                    <div className="flex flex-row justify-between items-center gap-4 mb-8">
                        <h1 className="my-0">{selectedTab === 'tracked' ? 'Tracked keywords' : 'New keywords'} ({period})</h1>
                        <div className="flex flex-row gap-2">
                            <button onClick={handlePeriodChange("hour")}>Hour</button>
                            <button onClick={handlePeriodChange("day")}>Day</button>
                            <button onClick={handlePeriodChange("week")}>Week</button>
                        </div>
                    </div>

                    <Suspense fallback={<Loading />}>
                        <StatsTable tab={selectedTab} activeKeyword={keyword} onReset={handleKeywordReset("")} period={period} full={full} onClick={(keyword: string) => setKeyword(keyword)} isUnique={isUnique}/>
                    </Suspense>
                </div>
            </div>
        </>
    );
};

export default MainPage;

export const loadTweets = async ({ request }: any) => {
    const url = new URL(request.url);
    console.log('LOAD');

    const response = await client.get(`/tweets?limit=8`).then(response => response.data);
    return defer({ response });
};
