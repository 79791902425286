import { FC, useRef } from 'react';
import { Suspense } from 'react';
import { useIsVisible } from '../../../hooks/useIsVisible';
import { TweetsPageProps } from '../Model/Interfaces';
import { KeywordsTable } from './KeywordsTable/KeywordsTable';
import Loading from '../../../components/Loader/DataLoader/DataLoader';
import MobileLiveFeed from '../LiveFeed/MobileLiveFeed';
import Alerts from '../Alerts/Alerts';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

type KeywordsProps = {
    selectedTab: 'tracked' | 'new' | 'live_feed' | 'alerts';
    alerts: Array<any>;
    onTabSelectHandler: Function;
    onPeriodChangeHandler: Function;
    onKeywordResetHandler: Function;
    keyword: string;
    period: string;
    full: boolean;
    setKeyword: Function;
    setTweetFromDate: Function;
    tweetsData: TweetsPageProps;
    isLoadingTweets: boolean;
    isLoadingAlerts: boolean;
    isInfiniteTweetsLoading: boolean;
    onScrolledToBottom: Function;
};

const TABS = [
    {
        label: 'New keywords',
        id: 'new'
    },
    {
        label: 'Tracked keywords',
        id: 'tracked'
    }
];

const Keywords: FC<KeywordsProps> = ({
    full,
    alerts,
    period,
    keyword,
    tweetsData,
    setKeyword,
    setTweetFromDate,
    selectedTab,
    isLoadingTweets,
    isLoadingAlerts,
    isInfiniteTweetsLoading,
    onTabSelectHandler,
    onPeriodChangeHandler,
    onKeywordResetHandler,
    onScrolledToBottom
}) => {
    const mobileLiveFeedRef = useRef<HTMLButtonElement | null>(null);
    const isMobileLiveFeedButtonVisible = useIsVisible(mobileLiveFeedRef);

    const onKeywordSelectHandler = (keyword: string, time?: string) => {
        setKeyword(keyword);
        if (time) {
            setTweetFromDate(time);
        } else setTweetFromDate(null);
        if (isMobileLiveFeedButtonVisible) {
            onTabSelectHandler('live_feed');
        }
    };

    let title = 'Tracked Keywords';
    let subtitle = '';

    if (selectedTab === 'new') {
        title = 'New Keywords';
    } else if (selectedTab === 'alerts') {
        title = 'Alerts';
        subtitle = '5+ mentions within 4 hour';
    }

    return (
        <div className="bg-white md:bg-compBG p-0 md:p-6 mt-7 md:mt-0 md:h-max w-full md:w-auto">
            <div className="bg-white p-0 md:p-4">
                <div className="bg-white md:bg-compBG p-0 md:p-2 flex space-x-3 justify-center">
                    <div className="tab flex space-x-0 md:space-x-3 border-b-[1px] border-b-gray-200 w-full md:border-none justify-between">
                        {TABS.map((tab, tabIndex) => (
                            <button
                                key={tabIndex}
                                className={`${
                                    selectedTab === tab.id
                                        ? 'bg-white md:shadow-md text-blue-800 md:text-greyState border-b-2 border-b-blue-800'
                                        : 'md:bg-compBG bg-white text-greyState'
                                } tablinks text-sm px-2 md:px-20
                                md:hover:text-AzureBlue md:hover:border-b-0 hover:bg-white md:hover:shadow-md 
                                md:active:bg-white rounded-none md:border-b-0 transition duration-500`}
                                onClick={() => onTabSelectHandler(tab.id)}>
                                {tab.label}
                            </button>
                        ))}

                        <button
                            ref={mobileLiveFeedRef}
                            className={`${
                                selectedTab === 'live_feed'
                                    ? 'bg-white md:shadow-md text-blue-800 md:text-greyState border-b-2 border-b-blue-800'
                                    : 'md:bg-compBG bg-white text-greyState'
                            } tablinks text-sm px-2 md:px-20 md:hover:text-AzureBlue md:hover:border-b-0 hover:bg-white md:hover:shadow-md 
                            hover:rounded-b-none md:active:bg-white rounded-none md:border-b-0 inline-block md:hidden transition duration-500`}
                            onClick={() => onTabSelectHandler('live_feed')}>
                            Live feed
                        </button>
                    </div>
                </div>

                {(selectedTab === 'tracked' || selectedTab === 'new' || selectedTab === 'alerts') && (
                    <div id="topKeywords" className="tabcontent">
                        <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between mt-7">
                            <div>
                                <p className="text-themeBlue font-inter font-medium text-2xl md:text-lg">{title}</p>
                                <p className="text-bBlue font-Inter font-light text-base mt-2 text-sm inline-block">
                                    {subtitle}
                                </p>
                            </div>

                            <div className="flex items-center justify-between space-x-3 min-w-full md:min-w-min mt-7 md:mt-0">
                                {selectedTab !== 'alerts' && (
                                    <div className="flex items-center">
                                        <span
                                            onClick={onPeriodChangeHandler('hour')}
                                            className={`px-6 md:px-4 py-2 text-AzureBlue text-sm font-medium font-Inter hover:bg-compBG rounded-l-md border-[1px] border-r-0 border-gray-300 cursor-pointer ${
                                                period === 'hour' ? 'bg-compBG' : ''
                                            }`}>
                                            4h
                                        </span>

                                        <span
                                            onClick={onPeriodChangeHandler('day')}
                                            className={`px-6 md:px-4 py-2 text-AzureBlue text-sm font-medium font-Inter hover:bg-compBG border-[1px] border-gray-300 cursor-pointer ${
                                                period === 'day' ? 'bg-compBG' : ''
                                            }`}>
                                            24h
                                        </span>

                                        <span
                                            onClick={onPeriodChangeHandler('week')}
                                            className={`px-6 md:px-4 py-2 text-AzureBlue text-sm font-medium font-Inter hover:bg-compBG rounded-r-md border-[1px] border-l-0 border-gray-300 cursor-pointer ${
                                                period === 'week' ? 'bg-compBG' : ''
                                            }`}>
                                            7d
                                        </span>
                                    </div>
                                )}

                                <button
                                    onClick={() => onTabSelectHandler('alerts')}
                                    className={`flex items-center md:space-x-2 ml-auto px-4 py-2 text-AzureBlue text-sm font-medium font-Inter border-[1px] border-gray-300 rounded-md bg-white hover:bg-compBG
                                     ${selectedTab === 'alerts' ? 'bg-compBG' : ''}`}>
                                    <img src="/images/Login/AlertIcon.svg" alt="" className="inline-block" />

                                    <span>Alerts</span>
                                </button>
                            </div>
                        </div>

                        <div className="table-container mt-7">
                            {selectedTab !== 'alerts' && (
                                <Suspense fallback={<TableDataLoaderUI />}>
                                    <ErrorBoundary selectedTab={selectedTab} period={period} keyword={keyword}>
                                        <KeywordsTable
                                            tab={selectedTab}
                                            activeKeyword={keyword}
                                            isLoadingTweets={isLoadingTweets}
                                            onReset={onKeywordResetHandler('')}
                                            period={period}
                                            full={full}
                                            onClick={onKeywordSelectHandler}
                                        />
                                    </ErrorBoundary>
                                </Suspense>
                            )}
                            {selectedTab === 'alerts' && (
                                <Suspense fallback={<TableDataLoaderUI />}>
                                    <Alerts
                                        alerts={alerts}
                                        activeKeyword={keyword}
                                        isLoadingTweets={isLoadingTweets}
                                        isLoadingAlerts={isLoadingAlerts}
                                        onReset={onKeywordResetHandler('')}
                                        onKeywordSelect={onKeywordSelectHandler}
                                    />
                                </Suspense>
                            )}
                        </div>
                    </div>
                )}

                {selectedTab === 'live_feed' && (
                    <MobileLiveFeed
                        tweetsData={tweetsData}
                        isLoadingTweets={isLoadingTweets}
                        keyword={keyword}
                        isInfiniteTweetsLoading={isInfiniteTweetsLoading}
                        onScrolledToBottom={onScrolledToBottom}
                        onResetKeywords={onKeywordResetHandler('')}
                    />
                )}
            </div>
        </div>
    );
};

const TableDataLoaderUI = () => {
    return (
        <div className="flex justify-center mt-4">
            <Loading />
        </div>
    );
};

export default Keywords;
