import { FC, Suspense, useCallback, useReducer } from "react";
import { Await, defer, Link, NavLink, useLoaderData, useSubmit } from "react-router-dom";
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { GroupDialogButton } from "../../components/GroupDialog/GroupDialogButton";
import { Search } from "../../components/Search/Search";
import { Keyword } from "../../types/models";
import { checkAuth } from "../../utils/auth";
import { client, getErrorMessage } from "../../utils/client";

type KeywordPageProps = {
  keywords: Keyword[];
  next?: string;
};

type Response = {
  response: KeywordPageProps;
}

const KeywordPage: FC = () => {
  const { response } = useLoaderData() as Response;
  const [open, toggle] = useReducer((state: boolean) => !state, false);
  const submit = useSubmit();

  const handleAction = useCallback((act: string, id: string) => () => {
    const formData = new FormData();
    formData.append('keyword', id);
    if (act === 'blacklist') {
      formData.append('blacklist', 'true');
      formData.append('ignore', 'false');
    }
    if (act === 'ignore') {
      formData.append('ignore', 'true');
    }
    submit(formData, { method: act === 'delete' ? 'delete' : 'put' });
  }, [submit]);

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-4">
        <h1>Keywords</h1>
        <div className="flex flex-row justify-end items-center gap-4">
          <Search />
          <NavLink to="/admin/keywords/group">Groups</NavLink>
          <NavLink to="/admin/keywords/ignored">Ignored</NavLink>
          <NavLink to="/admin/keywords/blacklisted">Blacklisted</NavLink>
          <NavLink to="/admin/keywords/probable">Probable keywords</NavLink>
          <button onClick={toggle}>Add new</button>
        </div>
      </div>
      <CustomForm isOpen={open} onClose={toggle} label="Keywords" bulk />
      <Suspense
        fallback={<p>Loading keywords...</p>}
      >
        <Await
          resolve={response}
          errorElement={
            <p>Error loading keywords...</p>
          }
        >
          {(res: KeywordPageProps) => (
            <table className="w-full">
              <thead>
                <tr>
                  <th>Keyword</th>
                </tr>
              </thead>
              <tbody>
              {res.keywords.map(item => (
                <tr key={item.keyword}>
                  <td>
                    <div className="flex flex-row justify-between items-center">
                      <Link to={`/admin/feed?keyword=${item.keyword}`}>{item.keyword}</Link>
                      <div className="flex flex-row justify-end items-center gap-2">
                        <button onClick={handleAction('delete', item.keyword)}>Delete</button>
                        <button onClick={handleAction('ignore', item.keyword)}>Ignore</button>
                        <button onClick={handleAction('blacklist', item.keyword)}>Blacklist</button>
                        <GroupDialogButton keyword={item.keyword} />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              {res.next && (
                <tr>
                  <td>
                    <NavLink to={`/admin/keywords?cursor=${res.next}`}>Load more</NavLink>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          )}
        </Await>
      </Suspense>
    </>
  );
};

export const loadKeywords = async ({ request }: any) => {
  if (!await checkAuth()) return null;
  const url = new URL(request.url);
  const response = client.get(`/admin/keyword${url.search}`).then(response => response.data);
  return defer({ response });
};

export const addKeyword = async ({ request }: { request: Request }) => {
  try {
    const formData = await request.formData();
    if (request.method.toLowerCase() === 'put') {
      return await client.put(`/admin/keyword`, {
        keyword: formData.get('keyword'),
        ignore: formData.get('ignore') === 'true',
        blacklist: formData.get('blacklist') === 'true',
      });
    }
    if (request.method.toLowerCase() === 'delete') {
      return await client.delete(`/admin/keyword/${formData.get('keyword')}`);
    }
    return await client.post('/admin/keyword', { keyword: formData.get('value') });
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export default KeywordPage;
