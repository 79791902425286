import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import { client } from '../../../../utils/client';
import { useIsScored } from '../../../../utils/store';
import { RenderStats, RenderStatsProps } from '../../../RenderStats/RenderStats';
import styles from './KeywordsTable.module.css';
import Loading from "../../../Loader/DataLoader/DataLoader";

const fetchStats = async (period: string, scored: boolean) =>
    client
        .get<{ items: RenderStatsProps[] }>(`/stats?period=${period}${scored ? '&scored=true' : ''}&is_unique=true`)
        .then((res) => res.data.items);
const fetchStatsNew = async (period: string, scored: boolean) =>
    client
        .get<{ items: RenderStatsProps[] }>(`/stats-new?period=${period}${scored ? '&scored=true' : ''}&is_unique=true`)
        .then((res) => res.data.items);

type KeywordsTableProps = {
    period: string;
    full?: boolean;
    activeKeyword: string;
    isLoadingTweets: boolean;
    onReset?: () => void;
    onClick?: (keyword: string) => void;
    tab: 'tracked' | 'new';
};

export const KeywordsTable: FC<KeywordsTableProps> = ({ tab, period, full, isLoadingTweets, activeKeyword, onReset, onClick }) => {
    const scored = useIsScored();
    const [items, setItems] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [sort, setSort] = useState<keyof RenderStatsProps>('change');
    const [direction, setDirection] = useState<'asc' | 'desc'>('desc');


    const refreshTable = async (fetchData = true) => {
        try {
            let itemsToSet = items;
            if(fetchData) {
                setIsLoading(true);
                if (tab === 'tracked') {
                    itemsToSet = await fetchStats(period, scored);
                }  else {
                    itemsToSet = await fetchStatsNew(period, scored);
                }
            }

            const sortedItems = [...itemsToSet].sort((a:any, b:any) => {
                if (typeof a[sort] === 'number') {
                    return direction === 'asc' ? Number(a[sort]) - Number(b[sort]) : Number(b[sort]) - Number(a[sort]);
                } else {
                    return direction === 'asc'
                        ? String(a[sort]).localeCompare(String(b[sort]))
                        : String(b[sort]).localeCompare(String(a[sort]));
                }
            });

            setItems(sortedItems);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        refreshTable();
    }, [tab, period]);

    useEffect(() => {
        refreshTable(false);
    }, [direction, sort]);



    const handleSort = useCallback((sort: keyof RenderStatsProps, direction: string) => {
        setSort(sort);
        setDirection(direction as 'asc' | 'desc');
    }, []);

    return isLoading ? <StatsLoader/> : (
        <>
            <table className={`table-auto w-full ${styles.KeywordsDataTable} ${styles.scrollable}`} cellSpacing="0" cellPadding="0">
                <thead className="border-b-compBG ">
                    <tr>
                        <th className="text-greyState font-Inter font-normal text-xs w-1/3 md:w-1/2 text-left pl-0">
                            <SortLabel
                                sort="keyword"
                                label="Keyword"
                                onSortChange={handleSort}
                                currentSort={sort}
                                currentDirection={direction}
                            />
                        </th>
                        <th className="text-greyState font-Inter font-normal w-1/3 md:w-1/4 text-xs">
                            <SortLabel
                                sort="change"
                                label="Mentions growth"
                                onSortChange={handleSort}
                                currentSort={sort}
                                currentDirection={direction}
                            />
                        </th>
                        <th className="text-greyState font-Inter font-normal w-1/3 md:w-1/4 text-xs">
                            <SortLabel
                                sort="mentions"
                                label="Mentions"
                                onSortChange={handleSort}
                                currentSort={sort}
                                currentDirection={direction}
                            />
                        </th>
                    </tr>
                </thead>

                <tbody className="text-center">
                   {items.length === 0 && (
                        <tr>
                            <td colSpan={6}>No data...</td>
                        </tr>
                    )}
                   { console.log('items: ', items) }
                    {items.map((item: any) => (
                        // @ts-ignore
                        <RenderStats
                            // @ts-ignore
                            allProperties={full}
                            // @ts-ignore
                            onReset={onReset}
                            isLoadingTweets={isLoadingTweets}
                            // @ts-ignore
                            onClick={onClick}
                            key={item.keyword}
                            // @ts-ignore
                            active={item.keyword === activeKeyword}
                            showUnique={tab === 'tracked'}
                            {...item}
                        />
                    ))}
                </tbody>
            </table>
        </>
    );
};

type SortLabelProps = {
    sort: keyof RenderStatsProps;
    label: string;
    onSortChange: (sort: keyof RenderStatsProps, direction: 'asc' | 'desc') => void;
    currentSort: keyof RenderStatsProps;
    currentDirection: 'asc' | 'desc';
};

export const SortLabel: FC<SortLabelProps> = ({
    sort,
    currentSort,
    label,
    onSortChange: setSort,
    currentDirection
}) => {
    const active = useMemo(() => {
        if (sort === currentSort) {
            return currentDirection;
        }
        return false;
    }, [sort, currentSort, currentDirection]);

    const onClick = useCallback(() => {
        if (active) {
            setSort(sort, active === 'asc' ? 'desc' : 'asc');
        } else {
            setSort(sort, 'desc');
        }
    }, [active, setSort, sort]);

    return (
        <span className="cursor-pointer" onClick={onClick}>
            {label} {active && (active === 'asc' ? '▲' : '▼')}
        </span>
    );
};


const StatsLoader = () => {
    return (
        <div className="flex justify-center items-center h-full mt-4">
            <Loading />
        </div>
    );
};
