import { FC, Suspense, useCallback } from "react";
import { Await, defer, Link, NavLink, useLoaderData, useRevalidator } from "react-router-dom";
import { Chip } from 'primereact/chip';
import { Tag } from 'primereact/tag';
import { client } from "../../utils/client";
import { Search } from "../../components/Search/Search";
import { checkAuth } from "../../utils/auth";

type KeywordPageProps = {
  groups: { id: string; name: string; children: { keyword: string}[] }[];
  next?: string;
};

type Response = {
  response: KeywordPageProps;
}

const KeywordGroupPage: FC = () => {
  const { response } = useLoaderData() as Response;
  const { revalidate } = useRevalidator();

  const handleRemoveKeyword = useCallback((group: string, keyword: string) => (event: React.MouseEvent<HTMLElement>) => {
    client.delete(`/admin/keyword/groups/${group}/${keyword}`).then(() => revalidate());
  }, [revalidate]);

  const handleRemoveGroup = useCallback((group: string) => (event: React.MouseEvent<HTMLElement>) => {
    client.delete(`/admin/keyword/groups/${group}`).then(() => revalidate());
  }, [revalidate]);

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-4">
        <h1>Keyword Group</h1>
        <div className="flex flex-row justify-end items-center gap-4">
          <Search />
          <NavLink to="/admin/keywords">Keywords</NavLink>
        </div>
      </div>
      <Suspense
        fallback={<p>Loading keywords...</p>}
      >
        <Await
          resolve={response}
          errorElement={
            <p>Error loading keywords...</p>
          }
        >
          {(res: KeywordPageProps) => (
            <table className="w-full">
            <thead>
              <tr>
                <th>Name</th>
                <th>Keywords</th>
              </tr>
            </thead>
            <tbody>
            {res.groups.map(item => (
              <tr key={item.id}>
                <td>
                  <div className="flex flex-row gap-4 justify-between items-center">
                    <Link to={`/admin/feed?q=${item.name}`} target={'_blank'}>{item.name}</Link>
                    <Tag icon="pi pi-times" severity="danger" value="Delete" onClick={handleRemoveGroup(item.id)} />
                  </div>
                </td>
                <td>
                  <div className="flex gap-2">
                    {item.children.map(child => <Chip key={child.keyword} label={child.keyword} removable onRemove={handleRemoveKeyword(item.id, child.keyword)} />)}
                  </div>
                </td>
              </tr>
            ))}
            {res.next && (
              <tr>
                <td>
                  <NavLink to={`/admin/keywords/group?cursor=${res.next}`}>Load more</NavLink>
                </td>
              </tr>
            )}
            </tbody>
          </table>
          )}
        </Await>
      </Suspense>
    </>
  );
};

export const loadKeywordGroup = async ({ request }: any) => {
  if (!await checkAuth()) return null;
  const url = new URL(request.url);
  const response = client.get(`/admin/keyword/groups${url.search}`).then(response => response.data);
  return defer({ response });
};

export default KeywordGroupPage;
