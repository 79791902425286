import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useContext } from 'react';
import { Magic } from 'magic-sdk';
import styles from './Header.module.css';
import { hardReload } from '../../../utils/url';
import MobileMenu from './MobileMenu/MobileMenu';
import HeaderContext from '../../../store/HeaderContext';
import { CONTACT_US, FAQ, TWITTER } from '../../../utils/constants/footer-urls';

const MENU_BUTTON_DEFAULT_CLASSES =
    'text-white text-base border-b-transparent border-b-2 hover:border-b-white hover:border-b-2 py-6';
const MENUS: any[] = [];
const EXTERNAL_MENUS = [
    {
        label: 'Twitter',
        to: TWITTER
    },
    {
        label: 'FAQ',
        to: FAQ
    },
    {
        label: 'Contact us',
        to: CONTACT_US
    }
];

const SECONDARY_TABS = ['alerts'];

type Props = {
    magic: Magic;
    setUserData: Function;
    setIsLoggedIn: Function;
    isLoggedIn: boolean;
    userData: any;
};

function Header({ magic, setUserData, setIsLoggedIn, isLoggedIn, userData }: Props) {
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
    const headerCtx = useContext(HeaderContext);
    const navigate = useNavigate();
    const isInActiveMainMenu = SECONDARY_TABS.indexOf(headerCtx.selectedSubMenu || '') > -1;

    const onLogoutHandler = async () => {
        try {
            await magic.user.logout();
            setIsLoggedIn(false);
            setUserData({});
            navigate('/login');
        } catch (error) {
            console.log('ERROR ON LOGOUT', error);
        }
    };

    const onHamburgerClickHandler = () => {
        setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
    };

    const onSubMenuSelectHandler = (subMenu: 'alerts') => {
        headerCtx.setSelectedSubMenu(subMenu);
    };

    return (
        <>
            <header className="w-full bg-themeBlue mx-auto">
                <div className="header-container max-w-7xl flex items-center px-6 py-6 md:py-0 justify-between lg:px-0  text-white mx-auto">
                    {/* Logo and menu*/}

                    <div className="flex items-center space-x-8 ">
                        <a href="/">
                            <img src="/images/Login/LogoWhite.svg" width="150px" alt="" />
                        </a>

                        <div className="hidden md:flex items-center space-x-8">
                            <NavLink
                                to={'/'}
                                className={({ isActive }) =>
                                    isActive && !isInActiveMainMenu
                                        ? `border-b-white ${MENU_BUTTON_DEFAULT_CLASSES}`
                                        : MENU_BUTTON_DEFAULT_CLASSES
                                }
                                onClick={hardReload}>
                                Dashboard
                            </NavLink>
                            <span
                                className={
                                    headerCtx.selectedSubMenu === 'alerts'
                                        ? `border-b-white ${MENU_BUTTON_DEFAULT_CLASSES} cursor-pointer`
                                        : `${MENU_BUTTON_DEFAULT_CLASSES} cursor-pointer`
                                }
                                onClick={() => onSubMenuSelectHandler('alerts')}>
                                Alerts
                            </span>
                            {MENUS.map((menu, menuIndex) => (
                                <NavLink
                                    to={menu.to}
                                    className={({ isActive }) =>
                                        isActive
                                            ? `border-b-white ${MENU_BUTTON_DEFAULT_CLASSES}`
                                            : MENU_BUTTON_DEFAULT_CLASSES
                                    }
                                    key={menuIndex}>
                                    {menu.label}
                                </NavLink>
                            ))}
                            {EXTERNAL_MENUS.map((menu, menuIndex) => (
                                <a
                                    href={menu.to}
                                    key={menuIndex}
                                    target="_blank"
                                    className={MENU_BUTTON_DEFAULT_CLASSES}
                                    rel="noreferrer">
                                    {menu.label}
                                </a>
                            ))}
                            {/*{((userData as any)?.subscriptionType === 'NONE' ||*/}
                            {/*    !(userData as any)?.subscriptionType) && (*/}
                            {/*    <a*/}
                            {/*        href={`https://buy.stripe.com/cN2g339B82Sf8tWcMN?prefilled_email=${(*/}
                            {/*            userData as any*/}
                            {/*        ).email?.replace('@', '%40')}`}*/}
                            {/*        target="_blank">*/}
                            {/*        Subscribe*/}
                            {/*    </a>*/}
                            {/*)}*/}
                        </div>
                    </div>

                    {/* Menu Desktop & Mobile */}
                    <div className="flex items-center">
                        {/* Menu */}

                        {isLoggedIn && (
                            <div className="hidden font-sans md:flex md:space-x-8">
                                <div className="group">
                                    {((userData as any)?.subscriptionType === 'BASIC' ||
                                        (userData as any)?.subscriptionType === 'TRIAL') && (
                                        <a
                                            href={`https://billing.stripe.com/p/login/aEUaIcei33cA0zC5kk?prefilled_email=${(
                                                userData as any
                                            ).email?.replace('@', '%40')}`}
                                            target="_blank"
                                            className={`hidden md:inline-block ${MENU_BUTTON_DEFAULT_CLASSES} mx-4`}>
                                            {`Manage subscription`}
                                        </a>
                                    )}

                                    <button
                                        className="text-white bg-themeOrange rounded-lg px-4 py-2 font-Inter font-medium text-base hover:bg-themeOrange hover:bg-opacity-90"
                                        onClick={() => onLogoutHandler()}>
                                        Log out
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Hamburger Menu */}
                        <div onClick={onHamburgerClickHandler}>
                            <button
                                id="menu-btn"
                                type="button"
                                className={`md:hidden z-40 bloc ${styles.Hamburger} focus:outline-none ${
                                    isHamburgerMenuOpen ? styles.Open : ''
                                }`}>
                                <span className={`${styles.HamburgerTop}`}></span>
                                <span className={`${styles.HamburgerMiddle}`}></span>
                                <span className={`${styles.HamburgerBottom}`}></span>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu
                onCloseMobileMenu={() => setIsHamburgerMenuOpen(false)}
                onSubMenuSelectHandler={onSubMenuSelectHandler}
                isHamburgerMenuOpen={isHamburgerMenuOpen}
                userData={userData}
                onLogoutHandler={onLogoutHandler}
            />
        </>
    );
}

export default Header;
