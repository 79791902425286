import { FC, Suspense, useCallback } from "react";
import { Await, defer, Link, NavLink, useLoaderData, useSubmit } from "react-router-dom";
import { Search } from "../../components/Search/Search";
import { Keyword } from "../../types/models";
import { checkAuth } from "../../utils/auth";
import { client } from "../../utils/client";

type KeywordPageProps = {
  keywords: Keyword[];
  next?: string;
};

type Response = {
  response: KeywordPageProps;
}

const BlacklistedKeywordPage: FC = () => {
  const { response } = useLoaderData() as Response;

  const submit = useSubmit();

  const handleAction = useCallback((act: string, id: string) => () => {
    const formData = new FormData();
    formData.append('keyword', id);
    if (act === 'ignore') {
      formData.append('ignore', 'true');
      formData.append('blacklist', 'false');
    }
    submit(formData, { method: act === 'delete' ? 'delete' : 'put' });
  }, [submit]);

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-4">
        <h1>Blacklisted Keywords</h1>
        <Search />
      </div>
      <Suspense
        fallback={<p>Loading keywords...</p>}
      >
        <Await
          resolve={response}
          errorElement={
            <p>Error loading keywords...</p>
          }
        >
          {(res: KeywordPageProps) => (
            <table className="w-full">
            <thead>
              <tr>
                <th>Keyword</th>
              </tr>
            </thead>
            <tbody>
            {res.keywords.map(item => (
              <tr key={item.keyword}>
                <td>
                  <div className="flex flex-row justify-between items-center">
                    <Link to={`/admin/feed?keyword=${item.keyword}`} target={'_blank'}>{item.keyword}</Link>
                    <div className="flex flex-row justify-end items-center gap-2">
                      <button onClick={handleAction('delete', item.keyword)}>Delete</button>
                      <button onClick={handleAction('ignore', item.keyword)}>Whitelist</button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            {res.next && (
              <tr>
                <td>
                  <NavLink to={`/admin/keywords/ignored?cursor=${res.next}`}>Load more</NavLink>
                </td>
              </tr>
            )}
            </tbody>
          </table>
          )}
        </Await>
      </Suspense>

    </>
  );
};

export const loadBlacklistedKeywords = async ({ request }: any) => {
  if (!await checkAuth()) return null;
  const url = new URL(request.url);
  const response = client.get(`/admin/keyword/blacklisted${url.search}`).then(response => response.data);
  return defer({ response });
};

export default BlacklistedKeywordPage;
