// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDr_KWMmdQ4GyLuUxQa92zM2jBCggdCN-g",
  authDomain: "storyalert.firebaseapp.com",
  projectId: "storyalert",
  storageBucket: "storyalert.appspot.com",
  messagingSenderId: "839965775016",
  appId: "1:839965775016:web:9bf90b329c172122c09e9d",
  measurementId: "G-35T5WHK2ZE"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
