import React, { useEffect, useRef, useState } from 'react';
import { Tweet as ReactTweet } from 'react-tweet';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Tweet } from '../../../../types/models';

type Props = {
    tweet: Tweet;
    isLastTweet: boolean;
    onScrolledToBottom: Function;
};

function TweetCard({ tweet, isLastTweet, onScrolledToBottom }: Props) {
    const cardRef = useRef<HTMLDivElement>(null);
    const [isEmpty, setIsEmpty] = useState(false);

    useEffect(() => {
        if (!cardRef?.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            if (isLastTweet && entry.isIntersecting) {
                onScrolledToBottom();
                observer.unobserve(entry.target);
            }
        });

        observer.observe(cardRef.current);

        // Check the tweet after component mounts
        function checkTweet() {
            if (cardRef.current) {
                const tweetDiv = cardRef.current.querySelector("div");
                if (tweetDiv && tweetDiv.innerHTML.trim() === "") {
                    setIsEmpty(true);
                }
            }
        }

        // Also check the tweet whenever it changes
        const tweetObserver = new MutationObserver(checkTweet);
        if (cardRef.current) {
            tweetObserver.observe(cardRef.current, { childList: true, subtree: true });
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
                tweetObserver.disconnect();
            }
        }
    }, [isLastTweet]);

    return (
        <div ref={cardRef} className={isEmpty ? "" : "min-h-[100px]"}>
            <div className="light">
                <ReactTweet id={tweet.id} />
            </div>
        </div>
    );
}

export default TweetCard;
