import { FC, ReactNode, useState } from "react";
import { Form, useActionData } from "react-router-dom";

type CustomFormProps = {
  label: ReactNode;
  bulk?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export const CustomForm: FC<CustomFormProps> = ({ label, bulk, isOpen, onClose }) => {

  const [value, setValue] = useState('');
  const { error } = useActionData() as { error: string } ?? { error: null };

  if (!isOpen) return null;
  return (<Form method="post" className="w-full max-w-xl">
      <label htmlFor="value">{label}</label>
      <div className="flex flex-row justify-start items-center gap-4 mb-3 w-full">
        <div className="w-full">
          {bulk ? (
            <>
              <textarea name="value" value={value} onChange={e => setValue(e.target.value)} className="mb-0 w-full" />
              <p className="text-xs"><i>To add in bulk - separate values by newline, or comma.</i></p>
            </>
          ) : (
            <input type="text" name="value" value={value} onChange={e => setValue(e.target.value)} className="mb-0 w-full" />
          )}
          {error && <p>{error}</p>}
        </div>
      </div>
      <div className="flex flex-row justify-end items-center gap-4 mb-8">
        <button type="submit">Add</button>
        <button type="button" onClick={onClose} className="secondary">Cancel</button>
      </div>
  </Form>)
};
