import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { client } from "./client";
import { auth } from "./firebase";
import {Magic} from "magic-sdk";

const magic = new Magic('pk_live_DA773B8C8AD66D52');


export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type AuthData = {
  idToken: string;
  user: {
    id: number;
    identifier: string;
    email: string;
    name: string;
    picture: string;
    role: Role;
  };
};

export const getAuthData = (): Promise<AuthData> => {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(auth, async user => {

      if (user) {
        magic.user.getIdToken()
        .then(idToken => client.get('/auth')
        .then((response) => resolve({...response.data, idToken})))
        .catch(() => reject(new Error('Not authorized')))
        .finally(() => unsub());
      } else {
        unsub();
        reject(new Error('Not authorized'));
      }
    });
  });
};

export const signInWithGoogle = async () => new Promise((resolve, reject) => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then(async (result) => {
      const token = await magic.user.getIdToken();
      client.post('/auth', { token }).then(() => {
        resolve(token);
      }).catch((e) => {
        reject(e);
      });
    }).catch((error) => {
      reject(error);
    });
  });

  export const checkAuth = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const unsub = onAuthStateChanged(auth, user => {
        if (user) {
          magic.user.getIdToken()
          .then(() => resolve(true))
          .catch(() => resolve(false))
          .finally(() => unsub());
        } else {
          unsub();
          resolve(false);
        }
      });
    });
  };
