import React from 'react';
import ReactDOM from 'react-dom/client';
import dayjs from 'dayjs';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { hotjar } from 'react-hotjar';
import Analytics from 'analytics'
// @ts-ignore
import googleAnalytics from '@analytics/google-analytics';

const analytics = Analytics({
    app: 'awesome-app',
    plugins: [
        googleAnalytics({
            measurementIds: ['G-35T5WHK2ZE']
        })
    ]
})

analytics.page();
hotjar.initialize(3460261, 6);


dayjs.extend(localizedFormat);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
