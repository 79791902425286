import { FC } from 'react';
import { useContext } from 'react';
import { Tweet } from '../../types/models';
import LiveFeed from './LiveFeed/LiveFeed';
import Keywords from './Keywords/Keywords';
import { client } from '../../utils/client';
import { getAlerts } from './DataLoader/Alerts';
import { useLoaderData } from 'react-router-dom';
import HeaderContext from '../../store/HeaderContext';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useScoredQueryParams } from '../../hooks/useScoredQueryParams';
// @ts-ignore
import { useIsVisible } from '../../hooks/useIsVisible';

type Response = {
    response: TweetsPageProps;
};

type TweetsPageProps = {
    tweets: Tweet[];
    next?: string;
};

const HomePage: FC = () => {
    const feedRef = useRef<HTMLDivElement | null>(null);
    const isDesktopLiveFeed = useIsVisible(feedRef);
    const [full, setFull] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [period, setPeriod] = useState('day');
    const [tweetFromDate, setTweetFromDate] = useState(null);
    const [isKeywordRequestRunning, setIsKeywordRequestRunning] = useState(false);
    const [isLoadingTweets, setIsLoadingTweets] = useState(false);
    const [isInfiniteTweetsLoading, setIsInfiniteTweetsLoading] = useState(false);
    const [isLoadingAlerts, setIsLoadingAlerts] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const headerCtx = useContext(HeaderContext);
    const [selectedTab, setSelectedTab] = useState<'tracked' | 'new' | 'live_feed' | 'alerts'>('new');
    const [previouslySelectedTab, setPreviouslySelectedTab] = useState<'tracked' | 'new' | 'live_feed' | 'alerts'>('new');
    const { response } = useLoaderData() as Response;
    useScoredQueryParams();

    const [tweetsData, setTweetsData] = useState<TweetsPageProps>(response);

    useEffect(() => {
        if (window.location.pathname.includes('admin')) {
            setFull(true);
        }
    }, []);

    useEffect(() => {
        if (headerCtx.selectedSubMenu === 'alerts') {
            onTabSelectHandler(headerCtx.selectedSubMenu);
        }
    }, [headerCtx.selectedSubMenu]);

    useEffect(() => {
        if (selectedTab === 'live_feed' || selectedTab === 'alerts' || (!isDesktopLiveFeed)) {
            return;
        }
        onResetTweets();
    }, [period, selectedTab]);

    useEffect(() => {
        onResetTweetsWithKeyword();
    }, [keyword]);

    const onPeriodChangeHandler = useCallback(
        (period: string) => () => {
            setPeriod(period);
        },
        []
    );

    const onKeywordResetHandler = useCallback(
        (keyword: string) => () => {
            setTweetFromDate(null);
            if (isDesktopLiveFeed) {
                setKeyword(keyword);
            } else {
                setKeyword(keyword);
                setSelectedTab(previouslySelectedTab);
            }
        },
        [isDesktopLiveFeed, previouslySelectedTab]
    );

    const onLoadMoreTweets = async () => {
        if (tweetsData.next) {
            try {
                setIsInfiniteTweetsLoading(true);
                let endpoint = `/tweets?cursor=${tweetsData.next}&period=${period}&keyword=${keyword}&limit=88&is_unique=true`;
                if (tweetFromDate) {
                    endpoint = endpoint + `&from_date=${tweetFromDate}`;
                }
                let newTweets = await client.get(endpoint);
                setIsInfiniteTweetsLoading(false);

                setTweetsData({
                    tweets: [...tweetsData.tweets, ...newTweets.data.tweets],
                    next: newTweets.data.next
                });
            } catch (error) {
                setIsInfiniteTweetsLoading(false);
            }
        }
    };

    const onResetTweets = async () => {
        setIsLoadingTweets(true);

        try {
            console.log('1');
            let endpoint = `/tweets?period=${period}&limit=8&is_unique=true`;
            const newTweets = await client.get(endpoint);

            console.log('2');
            setTweetsData({
                tweets: [...newTweets.data.tweets],
                next: newTweets.data.next
            });

            console.log('3');

            setIsLoadingTweets(false);
        } catch (error) {
            setIsLoadingTweets(false);
        }
    };

    const onResetTweetsWithKeyword = async () => {
        setIsLoadingTweets(true);

        try {
            console.log('4');
            let endpoint = `/tweets?period=${period}&keyword=${keyword}&limit=8&is_unique=true`;
            if (tweetFromDate) {
                endpoint = endpoint + `&from_date=${tweetFromDate}`;
            }
            const newTweets = await client.get(endpoint);
            console.log('5');
            setTweetsData({
                ...tweetsData,
                tweets: [...newTweets.data.tweets],
                next: newTweets.data.next
            });

            console.log('6');
            setIsLoadingTweets(false);
        } catch (error) {
            setIsLoadingTweets(false);
        }
    };

    const onAlertsSlectionHandler = async (tab: 'tracked' | 'new' | 'live_feed' | 'alerts') => {
        if (tab == 'alerts') {
            setIsLoadingAlerts(true);
            setAlerts([]);
            const { error, response } = await getAlerts();
            if (!error && response) {
                setAlerts(response);
            }
            setIsLoadingAlerts(false);
        }
    };

    const onTabSelectHandler = (tab: 'tracked' | 'new' | 'live_feed' | 'alerts') => {
        setPreviouslySelectedTab(selectedTab);
        setSelectedTab(tab);
        onAlertsSlectionHandler(tab);
        headerCtx.setSelectedSubMenu(tab);
    };

    const onLiveFeedScrolledToBottom = () => {
        onLoadMoreTweets();
    };

    return (
        <>
            <div className="flex flex-row justify-center md:justify-end w-full md:mt-12 h-full px-4 md:px-0" id="stuff">
                <LiveFeed
                    feedRef={feedRef}
                    tweetsData={tweetsData}
                    isDesktopLiveFeed={isDesktopLiveFeed}
                    isLoadingTweets={isLoadingTweets}
                    isInfiniteTweetsLoading={isInfiniteTweetsLoading}
                    onScrolledToBottom={onLiveFeedScrolledToBottom}
                />

                <Keywords
                    full={full}
                    alerts={alerts}
                    period={period}
                    keyword={keyword}
                    tweetsData={tweetsData}
                    setKeyword={setKeyword}
                    setTweetFromDate={setTweetFromDate}
                    selectedTab={selectedTab}
                    isLoadingTweets={isLoadingTweets}
                    isInfiniteTweetsLoading={isInfiniteTweetsLoading}
                    isLoadingAlerts={isLoadingAlerts}
                    onTabSelectHandler={onTabSelectHandler}
                    onPeriodChangeHandler={onPeriodChangeHandler}
                    onKeywordResetHandler={onKeywordResetHandler}
                    onScrolledToBottom={onLiveFeedScrolledToBottom}
                />
            </div>
        </>
    );
};

export default HomePage;
