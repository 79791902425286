import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Magic } from 'magic-sdk';
import { useEffect, useState } from 'react';
import { client } from '../../utils/client';
import LdsRoller from '../../components/Loader/LdsRoller/LdsRoller';
import Header from './Header/Header';
import Footer from './Footer/Footer';

const magic = new Magic('pk_live_DA773B8C8AD66D52');
type Props = {};

export default function Layout({}: Props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isVerifiedLoginState, setIsVerifiedLoginState] = useState(false);
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const initializeLoginStatus = async () => {
            let loggedIn = false;
            try {
                loggedIn = await magic.user.isLoggedIn();
            } catch (e) {
                console.log(e);
            }

            setIsLoggedIn(loggedIn);

            if (loggedIn) {
                const response = await client.get(`/auth`);
                setUserData(response.data.user);
                // if (response.data.user?.subscriptionType === 'NONE' || !(response.data.user?.subscriptionType)) {
                //     window.location.href = (`https://buy.stripe.com/cN2g339B82Sf8tWcMN?prefilled_email=${(response.data.user as any).email?.replace('@', '%40')}`)
                // }
            } else {
                navigate('/login');
            }
            setIsVerifiedLoginState(true);
        };

        initializeLoginStatus();
    }, []);

    let mainContentUI = isVerifiedLoginState ? (
        <div className="flex flex-col justify-between h-full">
            <Header magic={magic} setUserData={setUserData} setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} userData={userData} />

            <div className="max-w-7xl mx-auto w-full flex-1	">
                <Outlet />
            </div>

            <Footer />
        </div>
    ) : (
        <LoginLoader />
    );

    return mainContentUI;
}

const LoginLoader = () => {
    return (
        <div className="h-screen flex justify-center items-center">
            <LdsRoller />
        </div>
    );
};
