import { FC, Suspense, useReducer } from "react";
import { Await, defer, NavLink, useLoaderData } from "react-router-dom";
import { ApiKey } from "../../components/ApiKey/ApiKey";
import { ApiKeysForm } from "../../components/CustomForm/ApiKeysForm";
import { Search } from "../../components/Search/Search";
import { TwitterApiKey } from "../../types/models";
import { checkAuth } from "../../utils/auth";
import { client, getErrorMessage } from "../../utils/client";

type Response = {
  response: KeysPageProps;
};

type KeysPageProps = {
  items: TwitterApiKey[];
  next?: string;
};

const KeysPage: FC = () => {
  const { response } = useLoaderData() as Response;
  const [open, toggle] = useReducer((state: boolean) => !state, false);

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-4">
        <h1>API Keys</h1>
        <div className="flex flex-row justify-end items-center gap-4">
          <Search />
          <button onClick={toggle}>Add new</button>
        </div>
      </div>
      <ApiKeysForm isOpen={open} onClose={toggle} />
      <Suspense
        fallback={<p>Loading API Keys...</p>}
      >
        <Await
          resolve={response}
          errorElement={
            <p>Error loading API keys!</p>
          }
        >
          {(res: KeysPageProps) => (
            <>
              {res.items.map((item) => (
                <ApiKey key={item.apiKey} item={item} />
              ))}
              {res.next && (
                <NavLink to={`/admin/keys?cursor=${res.next}`}>Load more</NavLink>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  );
};

export const loadApiKeys = async ({ request }: any) => {
  if (!await checkAuth()) return null;
  const url = new URL(request.url);
  const response = client.get(`/admin/keys${url.search}`).then(response => response.data);
  return defer({ response });
};

export const addApiKey = async ({ request }: { request: Request }) => {
  try {
    const formData = await request.formData();
    return await client.post('/admin/keys', { key: formData.get('key'), secret: formData.get('secret') }).then((res) => {
      return res;
    });
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export default KeysPage;
